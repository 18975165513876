<template>
  <v-footer
    class="py-4"

    height="auto"
  >
    <v-container class="mx-auto">
      <v-row align="center" justify="center" class="pa-2 text-body-2">
        Peppol Directory - an &nbsp;
        <a href="http://peppol.eu/" target="_blank">
          OpenPeppol AISBL
        </a>
        &nbsp; service
      </v-row>
      <v-row align="center" justify="center" class="text-body-2">Follow us on Twitter:&nbsp;
        <a href="https://twitter.com/PEPPOL_EU">@PEPPOL_EU</a>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'CoreFooter',

  data: () => ({
    items: [
      {
        href: '#!',
        icon: 'mdi-twitter',
      },
    ],
  }),
}
</script>
